import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa';

const MapContainer = () => {
  // API key: (to do) restrict API
  const apiKey = "AIzaSyAiQdUtb3eEY9re9j0Vpn3rLDne0TQmHt4";

  const pinIcon = <FaMapMarkerAlt color="red" size={32} />;
  // NOTE: google-map-react only takes wrapped component as its children!
  const Marker = ({text}) => {
    return (
      <div className='ICON' text={text}>
        {pinIcon}
      </div>
    );
  }
  
  // temparary address placeholder: (to do) 
  const lat = 49.21328695715736;
  const lng = -122.98829661597544;

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={{ lat: lat, lng: lng }}
        defaultZoom={13}
        yesIWantToUseGoogleMapApiInternals
      >
        <Marker lat={lat} lng={lng} text="test"/>
      </GoogleMapReact>
    </div>
  );
};

export default MapContainer;