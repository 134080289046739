/**
 * @module fontLang
 * @description returns font className in Typography.module.css
 */

import font from "../Styles/Typography.module.css";

export default function fontLang(lang = 'en', fontWeight = 500, sans = true) {
	const validLang = new Set(['en', 'fr', 'sc', 'tc', 'hk']);
	const validWeight = new Set(['medium', 'bold']);

	if (!validLang.has(lang)) {
		throw new Error('fontLang error: Invalid language');
	}

	if (!validWeight.has(fontWeight)) {
		throw new Error('fontLang error: Invalid font weight');
	}

	return font[`${lang}-${sans ? '' : 'serif-'}${fontWeight}`];
};