import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { CentralBlock } from "../Components/DivWrapper";
import MapContainer from "../Components/MapContainer";

import css from "../Styles/Contact.module.css";
import font from "../Styles/Typography.module.css";
import fontLang from "../Utility/fontLang";

const text = {
  "open now": {
    "en": "We are open now.",
    "sc": "营业中",
    "hk": "營業中",
    "tc": "營業中",
  },
  "closed now": {
    "en": "We are closed now.",
    "sc": "休业中",
    "hk": "休業中",
    "tc": "休業中",
  },
  "open soon": {
    "en": "We will open soon.",
    "sc": "即将营业",
    "hk": "即將營業",
    "tc": "即將營業",
  },
  "close soon": {
    "en": "We will close soon.",
    "sc": "即将休业",
    "hk": "即將休業",
    "tc": "即將休業",
  },
  "closed": {
    "en": "closed",
    "sc": "休业",
    "hk": "休業",
    "tc": "休業",
  },
};


function Contact() {
  // alert(`height: ${window.innerHeight}, width: ${window.innerWidth}`);
  const { lang } = useParams();

  // Custom component for formatting:
  // horizontally center and vertically stretch 
  const Block = (props) => {
    return (
      <div className={`${css.blockWrapper} ${props.className}`}>
        <div>
          {props.children}
        </div>
      </div>
    );
  };

  return (
    <div className={`${css.container} ${font['en-medium']}`}>
      <div className={css.padding} />

      <Block className={css.hour}>
        <FlashTitle />
        <CentralBlock className={css.gridItem}>
          <div>
            <span>MON - SAT</span>
            <span>10:00 - 20:00</span>
            <span>SUN</span>
            <span className={fontLang(lang, 'medium')}>{text["closed"][lang]}</span>
          </div>
        </CentralBlock>
        <CentralBlock className={css.gridItem}>
          <Timer />
        </CentralBlock>
      </Block>

      <Block className={css.address}>
        <div className={css.title}>
          <img src={`/images_svg/address_${lang}.svg`} alt="Address" />
        </div>
        <CentralBlock className={css.gridItem}>
          <p>
            <a
              href="https://www.google.com/maps/search/?api=1&query=Amy%27s+Beauty+Spa%2C+Royal+Oak+Avenue%2C+Burnaby%2C+BC%2C+Canada"
              target="_blank"
              rel="noreferrer noopener"
            // title="Redirect to Google maps"
            >
              7780 Royal Oak Ave <br />
              Burnaby, BC <br />
            </a>
            <span>
              V5J 4K4
            </span>
          </p>
        </CentralBlock>
        <CentralBlock className={css.gridItem}>
          <div>
            <MapContainer />
          </div>
        </CentralBlock>
      </Block>

      <Block className={css.contact}>
        <div className={css.title}>
          <img src={`/images_svg/contact_${lang}.svg`} alt="Contact" />
        </div>
        <CentralBlock className={css.gridItem}>
          <p>
            <a
              href="tel:+16046168472"
            // title="call"
            >
              (604) 616-8672
            </a> <br />
            <a
              href="mailto:amysbeautyspa@gmail.com"
            // title="email"
            >
              amysbeautyspa@gmail.com
            </a>
          </p>
        </CentralBlock>
      </Block>
    </div>
  )
}


/**
 * @module FlashTitle
 * @description Opening hours title with flash effects
 */
const FlashTitle = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef(null);
  const { lang } = useParams();

  const hourImages = [
    `/images_svg/hour_${lang}.svg`,
    `/images_svg/hour1_${lang}.svg`,
    `/images_svg/hour2_${lang}.svg`,
  ];

  const uniformRandom = (a, b) => {
    return a + (Math.random() * (b - a));
  };

  const uniformInt = (a, b) => {
    return a + Math.floor(Math.random() * (b - a + 1));
  }

  const normalPeriod = () => uniformRandom(6000, 8000);
  const flashPeriod = () => uniformRandom(100, 500);

  useEffect(() => {
    if (currentIndex === 0) {
      timeoutRef.current = setTimeout(() => {
        setCurrentIndex(uniformInt(1, hourImages.length - 1));
      }, normalPeriod());
    } else {
      timeoutRef.current = setTimeout(() => {
        setCurrentIndex(0);
      }, flashPeriod());
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [currentIndex]);

  return (
    <div className={css.title}>
      <img src={hourImages[currentIndex]} alt="Opening Hours" />
    </div>
  );
}


/**
 * @module Timer
 * @description Real-time timer section (right half of opening hours)
 */
const Timer = (props) => {
  // real-time timer
  const [time, setTime] = useState(new Date());
  // const [time, setTime] = useState(new Date("2023/07/16/19:59"));

  const { lang } = useParams();

  const getText = (t) => {
    return text[t][lang];
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // formatting time and log message
  const timeZone = 'America/Vancouver';
  const localTimeStr = time.toLocaleString('en-us', { timeZone });
  const localTime = new Date(localTimeStr);

  const day = localTime.getDay();
  const hour = localTime.getHours();
  const minute = localTime.getMinutes();

  const openHour = 10;
  const closeHour = 20;  // I assume close hour is 20:59 pm.

  const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

  let logMessage; let className;
  if (day === 0) {
    // closed on Sunday
    logMessage = getText("closed now");
    className = css.closed;
  } else if ((openHour - 1) <= hour && hour < openHour) {
    logMessage = getText("open soon");
    className = css.soon;
  } else if (openHour <= hour && hour < closeHour - 1) {
    logMessage = getText("open now");
    className = css.open;
  } else if (closeHour - 1 <= hour && hour < closeHour) {
    logMessage = getText("close soon");
    className = css.soon;
  } else {
    logMessage = getText("closed now");
    className = css.closed;
  }

  return (
    <div className={css.timer}>
      <span className={font["seven-segment"]}>{formattedTime}</span>
      <span className={`${className} ${fontLang(lang, 'medium')}`}>
        {logMessage}
      </span>
    </div>
  );
}

export default Contact;