import { useContext } from "react";
import { usePeople } from "../Pages/People";
import css from "../Styles/People.module.css";

function NavIcon() {

  const { peopleLength, selectedIndex, setSelectedIndex } = usePeople();

  const goLeft = () => {
    if (selectedIndex >= 0) {
      setSelectedIndex((selectedIndex - 1 + peopleLength) % peopleLength);
    }
  };

  const goRight = () => {
    if (selectedIndex >= 0) {
      setSelectedIndex((selectedIndex + 1) % peopleLength);
    }
  };

  return (
    <div className={css.navContainer}>
      <div className={css.left} onClick={goLeft} />
      {/* <div className={css.up} onClick={() => {window.scrollTo(0,0)}} /> */}
      <div className={css.right} onClick={goRight} />
    </div>
  );
}

export default NavIcon;