// Provides different types of div wrappers

// ABANDANED
const Wrapper = (props) => {
	const getRand = () => {
		return 200 + Math.floor(Math.random() * 56);
	};

	// if useBackground is true, show div background
	let style = props.style;
	if (props.useBackground) {
		style = {
			...style,
			backgroundColor: `rgba(${getRand()}, ${getRand()}, ${getRand()}, 1)`
		};
	}

	return (
		<div style={style} {...props} />
	);
};


// full size block with central content
const CentralBlock = (props) => {
	const style = {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	};

	return (
		<div style={style} {...props} />
	);
};

export { Wrapper, CentralBlock };