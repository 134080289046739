/**
 * @module loadImage
 * @description load image (mostly for preloading)
 */

const loadImage = src => {
	return new Promise((resolve, reject) => {
		const loadImg = new Image();
		loadImg.src = src;
		loadImg.onload = () => {
			resolve(loadImage.url);
		}
		loadImg.onerror = err => reject(err);
	})
}

export default loadImage;