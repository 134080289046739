import { createContext, useContext, useEffect, useState } from "react";
import NavIcon from "../Components/NavIcon";
import css from "../Styles/People.module.css";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import fontLang from "../Utility/fontLang";
import loadImage from "../Utility/loadImage";

const PeopleContext = createContext();
export function usePeople() {
  return useContext(PeopleContext);
};

const text = {
  "spec": {
    "en": "spec",
    "sc": "专业",
    "hk": "專業",
    "tc": "專業",
  },
  "team": {
    "en": "Our Team",
    // "sc": "Our Team",
    "sc": "我们的团队",
    "hk": "我們的團隊",
    "tc": "我們的團隊",
  },
};

export default function People() {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [people, setPeople] = useState([]);
  const selectedPeople = people[selectedIndex];
  const peopleLength = people.length;
  const { lang } = useParams();

  // local data fetcher
  useEffect(() => {
    // Fetch data from JSON file
    fetch(`/personnel/bio.json`)
      .then((res) => res.json())
      .then(data => data.map(e => ({
        ...e,
        spec: e.spec[lang],
        desc: e.desc[lang],
        avatarUrl: e.img,
        bioUrl: e.img
      })))
      .then(data => {
        setPeople(data);
        // console.log(data);
      })
      .catch(err => console.log("Failed to load personnel data", err));
  }, [lang]);

  const content = (
    <div className={css.people}>
      <PeopleBar />

      {/* Comment out to disable displaying people info */}
      {/* {selectedPeople &&
      <PeopleInfo people={selectedPeople} />
    } */}

      {/* <NavIcon /> */}
    </div>
  );

  return (
    <PeopleContext.Provider value={{
      people, selectedIndex, setSelectedIndex, peopleLength
    }}>
      <PeopleBar />

      {/* Comment out to disable displaying people info */}
      {/* {selectedPeople &&
        <PeopleInfo people={selectedPeople} />
      } */}
      {/* <NavIcon /> */}
    </PeopleContext.Provider>
  );
}


function PeopleBar() {
  const { people, selectedIndex, setSelectedIndex } = usePeople();
  const { lang } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [imgHeight, setImgHeight] = useState(0);

  useEffect(() => {
    // Preload images
    if (people) {
      const IMAGES = people.map(p => p.avatarUrl);
      Promise.all(IMAGES.map(image => loadImage(image)))
        // .then(() => {
        //   const img = new Image();
        //   img.src = people[0].avatarUrl;
        //   setImgHeight(img.height);
        // })
        .then(() => setLoaded(true))
        .catch(err => console.log("Failed to load images", err));
    }
  }, [people]);

  // add select
  const handleClick = (name) => {
    // set select block
    setSelectedIndex(people.findIndex(p => p.name === name));
    // console.log(selectedIndex);
    // jump to next page
    setTimeout(() => {
      window.scrollTo(0, window.innerHeight);
    }, 100);
  };

  // const loading = <div style={{ 'color': 'white', 'fontSize': '80px' }}>LOADING</div>;
  const loading = null;
  const content = (
    <div>
      <div className={`${css.barTitle} ${fontLang(lang, 'bold', false)}`}>
        {text["team"][lang]}
      </div>

      <div className={css.barInner}>
        {people.map((people, index) =>
          <div
            className={`${css.BioBlock} ${index === selectedIndex && css.selectedBio}`}
            onClick={() => { handleClick(people.name) }}
            key={index}
          >
            <img src={people.avatarUrl} alt={selectedIndex} />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className={css.barContainer}>
      {loaded ? content : loading}
    </div>
  );
}


function PeopleInfo({ people }) {
  const { lang } = useParams();

  return (
    <div className={css.bioContainer}>

      <img src={people.bioUrl} className={css.bioPhoto} alt={people.name} />

      <div className={css.bioText}>
        <div>
          <span className={fontLang(lang, 'bold')} style={{ "fontSize": "128px", "fontWeight": "500", "letterSpacing": "0em" }}>
            {people.name}
          </span>
        </div>

        <div className={fontLang(lang, 'bold')} style={{ "display": "flex", "alignItems": "center" }}>
          <span style={{
            "fontSize": "24px", "borderRadius": "10px",
            "color": "black", "backgroundColor": "white",
            "padding": "0px 8px 0px 8px", "marginRight": "24px",
          }}>
            {text.spec[lang]}
          </span>
          {people.spec.map(s => (<span key={s} style={{
            "marginRight": "18px", "fontSize": "30px", "letterSpacing": "0em"
          }}>{s}</span>))}
        </div>

        <div>
          <span className={fontLang(lang, 'medium')} style={{ "fontSize": "18px" }}>
            {people.desc}
          </span>
        </div>
      </div>
    </div>
  );
}