import { BrowserRouter as Router, Switch, Route, Redirect, useRouteMatch, useParams } from 'react-router-dom';
import './App.css';
import NavBar from './Components/NavBar';
import Contact from './Pages/Contact';
import Service from './Pages/Service';
import Home from "./Pages/Home";
import People from './Pages/People';
import ScrollToTop from './Components/ScrollToTop';

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <Switch>
        {/* Uncommnet this to enable redirecting on default page */}
        <Route exact path="/" render={() => <Redirect to="/en" />} />
        {/* <Route exact path="/" render={() => <Redirect to="/en/people" />} /> */}
        <Route path="/:lang(en|sc|hk|tc)">
          {/* NOTE: keep NavBar and ContentWrapper in the same Route level
          so that useRouteMatch() gets the correct path, url */}
          <NavBar />
          <ContentWrapper />
        </Route>
      </Switch>
    </Router>
  );
}

function ContentWrapper() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/people`} children={<People />} />
      {/* <Route path={`${path}/service`} children={<Service />} /> */}
      <Route path={`${path}/contact`} children={<Contact />} />
      <Route exact path={path} children={<Home />} />
    </Switch>
  );
}

export default App;
