import { useState, useEffect, useRef, createContext, useContext } from "react";
import Gallery from "../Components/Gallery";
import css from "../Styles/Home.module.css";
import shadow from "../Styles/Shadow.module.css";
import loadImage from "../Utility/loadImage";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const PromoContext = createContext();
const usePromo = () => useContext(PromoContext);

function Home() {
  const [display, setDisplay] = useState(false);
  const displayPromo = () => {
    if (!display) setDisplay(true);
  };

  return (
    <PromoContext.Provider value={{ display, displayPromo }}>
      <div className={css.home}>
        <LogoAnimation />
        <Promotion />
        {/* <PlotOvershoot /> */}
      </div>
    </PromoContext.Provider>
  );
}


const Promotion = () => {
  const { display } = usePromo();
  const { lang } = useParams();
  const images = [
    '/promotion/24-09-13/1.png',
    '/promotion/24-09-13/2.png',
    '/promotion/24-09-13/3.png',
  ];
  const imagesRaw = [
    '/promotion/24-09-13/1_raw.jpg',
    '/promotion/24-09-13/2_raw.png',
    '/promotion/24-09-13/3_raw.jpg',
  ];

  // const Poster = ({ src, width = 360 }) => {
  //   const handleClick = () => window.open(src, '_blank');
  //   return (
  //     <div className={css.poster} onClick={handleClick} style={{ 'width': `${width}px`, 'transform': `rotate(0deg)` }} >
  //       <div className={`${css['poster-image']} ${shadow['drop-shadow']} ${shadow.lifted}`}>
  //         <img src={src} />
  //       </div>
  //       <img className={css.tape} src='/images/tape.png' />
  //     </div>
  //   );
  // };

  const Poster = ({ src, srcRaw }) => {
    const handleClick = () => window.open(srcRaw, '_blank');
    return (
      <div className={css.poster} onClick={handleClick} >
        <img src={src} />
      </div>
    );
  };

  return (
    <div className={`${css.promotion} ${display ? css.open : css.hide}`}>
      <div style={{ 'backgroundImage': `url("/images/texture.png")` }}>
        <div>
          <div className={css.title}>
            <img src={`/images_svg/promo_${lang}.svg`} alt="Promotion" />
          </div>
          <div className={css.board}>
            {images.map((src, key) => (
              <Poster src={src} srcRaw={imagesRaw[key]} key={key} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};


// Generic overshoot function
const overshoot = (t1, t2, A, C, ratio = 0.9, zeta = 0.15, omega = 10, k = 1) => {
  const z = zeta;
  const w = 2 * Math.PI * omega;
  const eps = 1 / (k * w);
  const B = A + (C - A) / ratio;

  const dampedOscillator = t => (
    C + (B - C) * Math.exp(-z * w * t)
    * (Math.cos(w * t) + z * Math.sin(w * t))
  );

  const gradDampedOscillator = t => (
    -(B - C) * w * (1 + z ** 2) * Math.exp(-z * w * t)
    * Math.sin(w * t)
  );

  return t => {
    if (t <= t1) {
      return A;
    } else if (t <= t2 - eps) {
      const delta = t2 - t1 - eps;
      const gradTilde = gradDampedOscillator(-eps);
      const BTilde = dampedOscillator(-eps);

      const alpha = (gradTilde * delta - 2 * (BTilde - A)) / delta ** 3;
      const beta = (3 * (BTilde - A) - gradTilde * delta) / delta ** 2;
      return alpha * (t - t1) ** 3 + beta * (t - t1) ** 2 + A;
    } else {
      return dampedOscillator(t - t2);
    }
  };
};


const LogoAnimation = () => {
  const canvasRef = useRef(null);
  const startTimeRef = useRef(null);
  const { displayPromo } = usePromo();
  const [resize, setResize] = useState(false);
  const [loaded, setLoaded] = useState(false);

  // Preload all images
  useEffect(() => {
    const IMAGES = [
      '/amy-logo.png',
      '/images/home_title.png',
      '/images/heart.png',
    ];

    Promise.all(IMAGES.map(src => loadImage(src)))
      .then(() => setLoaded(true))
      .catch(err => console.log("Failed to load images", err));
  }, []);

  let dpr = window.devicePixelRatio || 1;
  const width = window.innerWidth;
  const height = window.innerHeight;
  const centerX = width / 2;
  const centerY = height / 2;

  // Animation effect
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    // Initialize canvas size
    const setSize = (width, height) => {
      // Update canvas dimensions
      canvas.width = width * dpr;
      canvas.height = height * dpr;

      // Update canvas CSS size
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;

      // Make sure to scale the context again
      ctx.scale(dpr, dpr);
    };

    setSize(window.innerWidth, window.innerHeight);

    // Canvas Auto-sizing
    const handleResize = () => {
      setSize(window.innerWidth, window.innerHeight);
      // Optional: re-render upon resizing
      setResize(!resize);
    };

    window.addEventListener('resize', handleResize);

    // -) Load Images
    const image = new Image();
    image.src = '/amy-logo.png';
    const title = new Image();
    title.src = '/images/home_title.png';
    const heart = new Image();
    heart.src = '/images/heart.png';

    // const draw = (timestamp) => {
    // -) Set Image Sizes
    // CAVEAT: logoRadius is actually the DIAMETER

    // CAVEAT: firebox does NOT recognize svg width and height,
    // therefore, we use empirical number (which is from Chrome display)

    const titleRatio = title.height / title.width;
    const heartRatio = heart.height / heart.width;
    // const titleRatio = 82 / 300;
    // const heartRatio = 398 / 444;

    const logoRadius = height * 0.2;
    const titleHeight = height * 0.2;
    const titleWidth = titleHeight / titleRatio;
    const marginWidth = logoRadius / 2 + height * 0.05;


    // -) Logo Animation Parameters
    const radiusInit = Math.sqrt(width ** 2 + height ** 2);
    const radiusFinal = logoRadius;
    const rotationInit = 0;
    const rotationFinal = 180;
    const translateInit = 0;
    const translateFinal = (titleWidth + marginWidth) / 2;
    const textInit = 0;
    const textFinal = (width - titleWidth + logoRadius + marginWidth) / 2;

    const t1 = 0.5;     // start coin flip
    const t2 = 0.75;    // start translation
    const t3 = 1;       // finish translation
    const t4 = 1.25;    // draw heart
    const duration = 4500;
    const t5 = t4;      // shrink canvas
    const tFinal = 2;   // end of animation

    const getRadius = overshoot(0, t1, radiusInit, radiusFinal, .95, .5, 5, .5);
    const getRotation = overshoot(t1, t2, rotationInit, rotationFinal, .85, .3, 4, 1);
    const getTranslateX = overshoot(t2, t3, translateInit, translateFinal, .95, 0.75, 3, 2);
    const getTranslateText = overshoot(t2, t3, textInit, textFinal, .95, 0.75, 3, 2);
    const getScaleX = t => Math.abs(Math.cos(getRotation(t) * Math.PI / 180));

    const lightGreen = '#CEE3CD';
    // const lightGreen = '#B5CAA0';
    const darkGreen = '#88B698';

    // Canvas resizing paramters
    const tShrink = 0.5;
    const heightRatioInit = 1;
    const heightRatioFinal = 0.5;
    const getHeightRatio = t => Math.min(1, t / tShrink) * heightRatioFinal + Math.max(0, 1 - t / tShrink) * heightRatioInit;

    // -) Start Drawing Animation
    const draw = (timestamp) => {    // NOTE: include all variables in draw() function
      if (!startTimeRef.current) startTimeRef.current = timestamp;
      const elapsed = timestamp - startTimeRef.current;

      // Normalize time to [0, t4]
      let t = elapsed / duration;
      let heightRatio = 1;
      if (t > t5) {
        heightRatio = getHeightRatio(t - t5);
        // display promotion
        displayPromo();
      }

      setSize(window.innerWidth, window.innerHeight * heightRatio);

      // 1. Fill background
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = lightGreen;
      ctx.fillRect(0, 0, width, height);

      // Center to middle
      // i. center horizontally; ii. scale down by heightRatio; iii. center vertically
      ctx.save();
      ctx.translate(centerX, 0);
      ctx.scale(heightRatio, heightRatio);
      ctx.translate(0, centerY);

      // 2. Draw text and its cover
      const textX = getTranslateText(t);

      ctx.fillStyle = 'black';
      ctx.drawImage(title, textX - centerX, -titleHeight / 2, titleWidth, titleHeight);

      // cover
      ctx.fillStyle = lightGreen;
      ctx.fillRect(-centerX - getTranslateX(t), -centerY, width / 2, height);

      // 3. Draw logo
      ctx.save();
      ctx.translate(- getTranslateX(t), 0);
      ctx.scale(getScaleX(t), 1);

      const degree = getRotation(t);
      const radius = getRadius(t);

      ctx.beginPath();
      ctx.arc(0, 0, radius, 0, 2 * Math.PI);
      ctx.fillStyle = darkGreen;
      ctx.fill();
      if (degree >= 90 && degree <= 270) {
        const radiusImg = 0.9 * radius;
        ctx.drawImage(image, -radiusImg, -radiusImg, 2 * radiusImg, 2 * radiusImg);
      }

      ctx.restore();

      // 4. Draw heart
      const heartHeightInit = 0;
      const heartHeightFinal = height * 0.06;

      const heartHeight = overshoot(1.4, 1.55, heartHeightInit, heartHeightFinal, .95, 0.75, 3, 2)(t);
      const heartWidth = heartHeight / heartRatio;

      if (t >= t4) {
        ctx.save();
        ctx.translate(textFinal + titleWidth - centerX, - height / 60);
        ctx.rotate(5 / 180 * Math.PI);
        ctx.drawImage(heart, -heartWidth / 2, -heartHeight / 2, heartWidth, heartHeight);
        ctx.restore();
      }

      // Request next frame if animation is not complete
      if (t < tFinal) animationFrameId = requestAnimationFrame(draw);
    };

    if (loaded) {
      // console.log(title.height, title.width, heart.height, heart.width);
      animationFrameId = requestAnimationFrame(draw);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameId);
    };
  }, [resize, loaded]);

  return <canvas ref={canvasRef} />;
};


// ===== OVERSHOOT =====
const PlotOvershoot = () => {
  // 3 Key Components in Animation

  // trial 1:
  // const getRotation = overshoot(0.35, 0.65, 0, 0.2, .9, .2, 5, 1);
  // const getRadius = overshoot(0, 0.35, 0.2, 0, .95, .5, 5, .5);
  // const getTranslateX = overshoot(0.65, 0.92, 0.2, 0, .95, .5, 5, .5);

  // trial 2:
  const t1 = 0.5;
  const t2 = 0.75;
  const t3 = 0.95;
  // const getRotation = overshoot(t1, t2, 0, 0.2, .95, .5, 5, 1);
  const getRotation = overshoot(t1, t2, 0, 0.2, .85, .15, 5, 1);
  const getRadius = overshoot(0, t1, 0.2, 0, .95, .5, 5, .5);
  // const getTranslateX = overshoot(t2-0.1, t3-0.1, 0.2, 0, .92, 0.3, 5, 10);
  const getTranslateX = overshoot(0.1, 0.4, 0.2, 0, .9, 0.15, 3, 2);

  return (
    <div>
      <FunctionPlotter func={getRotation} />
      <FunctionPlotter func={getRadius} />
      <FunctionPlotter func={getTranslateX} />
    </div>
  );
};

// FunctionPlotter.js
const FunctionPlotter = ({ func, width = 500, height = 500 }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Clear canvas
    ctx.clearRect(0, 0, width, height);

    // Set up axis
    ctx.beginPath();
    ctx.moveTo(0, height / 2);
    ctx.lineTo(width, height / 2);
    ctx.moveTo(0, 0);
    ctx.lineTo(0, height);
    ctx.strokeStyle = "#000";
    ctx.stroke();

    ctx.beginPath();
    for (let x = 0; x <= width; x += 1) {
      const normalizedX = x / width; // Convert canvas x to [0,1]
      const y = -func(normalizedX) * height; // Scale y to canvas height
      ctx.lineTo(x, height / 2 + y);
    }
    ctx.strokeStyle = "blue";
    ctx.stroke();
  }, [func, width, height]);

  return <canvas ref={canvasRef} width={width} height={height} style={{ 'position': 'relative', 'top': '100px' }} />;
}


export default Home;

