import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Automatically scrolls to top of the page upon 
 * second path name change 
 *
 * @component
 * @example
 * // Wrap this component with the outer router.
 * // e.g. scrolls to top when "/en/page" changes to "/en/people"
 * // Usage example:
 * // <ScrollToTop />
 */

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const prevPathnameRef = useRef(pathname);

  useEffect(() => {
    if (pathname !== prevPathnameRef.current) {
      // Check if the second part of the pathname has changed
      const currentPathParts = pathname.split('/');
      const prevPathParts = prevPathnameRef.current.split('/');
      
      if (currentPathParts[2] !== prevPathParts[2]) {
        window.scrollTo(0, 0);
      }

      // Update the previous pathname with the current one for the next comparison
      prevPathnameRef.current = pathname;
    }
  }, [pathname]);

  return null; // ScrollToTop component doesn't render anything
};
