import { Link, useHistory, useParams, useLocation, useRouteMatch } from 'react-router-dom';
import { createContext, useContext, useRef, useState, useEffect } from "react";
import css from '../Styles/Navbar.module.css';
import font from '../Styles/Typography.module.css';

const navBtns = [
  {
    name: "Home",
    to: "",
    // btnUrl: "/icon/logo-off.png",
    // boldUrl: "/icon/logo-on.png",
    btnUrl: "/amy-logo.png",
    boldUrl: "/amy-logo.png",
    iconHeight: 380,
  },
  {
    name: "People",
    to: "people",
    btnUrl: "/icon/people_outlined.svg",
    boldUrl: "/icon/people_filled.svg",
    iconHeight: 380,
  },
  // {
  //   name: "Service",
  //   to: "service",
  //   btnUrl: "/icon/service_outlined.svg",
  //   boldUrl: "/icon/service_filled.svg",
  //   iconHeight: 370,
  // },
  {
    name: "Contact",
    to: "contact",
    btnUrl: "/icon/contact_outlined.svg",
    boldUrl: "/icon/contact_filled.svg",
    iconHeight: 300,
  },
];

const iconHeightScaleRatio = 15;

const DropdownContext = createContext();

const useDropdown = () => (
  useContext(DropdownContext)
);

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !(dropdownRef.current.contains(event.target) || buttonRef.current.contains(event.target))) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleIsOpenChange = (bool) => {
    setIsOpen(bool);
  };

  const handleIsOpenSwitch = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <DropdownContext.Provider value={{ isOpen, handleIsOpenChange, handleIsOpenSwitch, buttonRef, dropdownRef }}>
      <div className={css.container}>
        <div className={css.center}>
          {
            navBtns.map(btn =>
              <NavBtn
                name={btn.name}
                to={btn.to}
                url1={btn.btnUrl}
                url2={btn.boldUrl}
                iconHeight={btn.iconHeight / iconHeightScaleRatio}
                key={btn.name}
              />
            )
          }
          {/* <Search /> */}
        </div>
        <LanguageBtn />
      </div>
      <LanguageDropdown />
    </DropdownContext.Provider>
  );
}

function NavBtn({
  name, to, url1, url2, iconHeight
}) {

  const loc = useLocation();
  const { lang, page } = useParams();
  const { url } = useRouteMatch();

  // console.log(lang, page);
  const currentPage = loc.pathname.split("/")[2] || "";  // second path parameter
  const isCurrentPage = currentPage === to;
  const iconUrl = isCurrentPage ? url2 : url1;

  // Update: use url parameter
  // const currentPage = page || "";
  // const isCurrentPage = currentPage === to;
  // const iconUrl = isCurrentPage ? url2 : url1;

  // special case for Home
  // const target = to === "" ? url : `${url}/${to}`; // this soln requres modification for isCurrentPage
  const target = `${url}/${to}`.replace("//", "/");   // easier soln to fix "//" in redirect url

  // console.log(url, target);
  // console.log(currentPage, to, isCurrentPage);
  // console.log(name, currentPage, locParser(loc.pathname), url);

  // for styling the logo icon only:
  const isLogoOff = name === 'Home' && !isCurrentPage;
  const isLogoOn = name === 'Home' && isCurrentPage;

  return (
    <Link to={target} className={`${css.navBtn} ${isLogoOff ? css['logo-off'] : isLogoOn ? css['logo-on'] : ''}`}>
      <img src={iconUrl} alt={name} style={{ height: `${iconHeight}px` }} />
    </Link>
  );
}


// Language Selection
function LanguageBtn() {
  const { buttonRef, handleIsOpenSwitch } = useDropdown();

  return (
    <img className={`${css.navBtn} ${css.dropdownButton}`} src="/icon/lang.svg" alt="lang"
      onClick={handleIsOpenSwitch} ref={buttonRef} />
  );
}

function LanguageDropdown() {
  const { dropdownRef, isOpen, handleIsOpenChange } = useDropdown();
  const pathname = useLocation().pathname;
  const history = useHistory();
  const { lang } = useParams();

  const handleLanguageChange = (lang) => {
    const newPathname = pathname.replace(/\/(en|sc|hk|tc)/, `/${lang}`);
    history.push(newPathname);
    handleIsOpenChange(false);
  }

  return (
    <div className={`${css.dropdownMenu} ${isOpen ? css.open : ''}`} ref={dropdownRef}>
      <ul>
        <li onClick={() => { handleLanguageChange("en") }} className={lang === 'en' ? font['en-heavy'] : font['en-medium']}>English</li>
        {/* French language current not released */}
        {/* <li onClick={() => { handleLanguageChange("fr") }}>Français</li> */}
        <li onClick={() => { handleLanguageChange("sc") }} className={lang === 'sc' ? font['sc-heavy'] : font['sc-medium']} >中文 - 内地</li>
        <li onClick={() => { handleLanguageChange("hk") }} className={lang === 'hk' ? font['hk-heavy'] : font['hk-medium']} >中文 - 港澳</li>
        <li onClick={() => { handleLanguageChange("tc") }} className={lang === 'tc' ? font['tc-heavy'] : font['tc-medium']} >中文 - 臺灣</li>
      </ul>
    </div>
  )
}

function Language() {
  const { isOpen, handleIsOpenChange, handleIsOpenSwitch, dropdownRef } = useDropdown();
  const pathname = useLocation().pathname;
  const history = useHistory();
  const { lang } = useParams();

  const handleLanguageChange = (lang) => {
    const regex = /\/[^/]+\//; // regex for /REPLACE/
    const newPathname = pathname.replace(regex, `/${lang}/`);
    history.push(newPathname);
    handleIsOpenChange(false);
  }

  return (
    <div className={`${css.dropdown} ${isOpen ? css.open : ''}`} ref={dropdownRef} >
      <img src="/icon/lang.svg" alt="lang" className={css.navBtn} onClick={handleIsOpenSwitch} />
      <div className={`${css.dropdownMenu} ${isOpen ? css.fadeIn : css.fadeOut}`}>
        <ul>
          <li onClick={() => { handleLanguageChange("en") }}>English</li>
          {/* French language current not released */}
          {/* <li onClick={() => { handleLanguageChange("fr") }}>Français</li> */}
          <li onClick={() => { handleLanguageChange("sc") }} className={lang === 'sc' ? font['sc-bold'] : font['sc-medium']} >中文/内地</li>
          <li onClick={() => { handleLanguageChange("hk") }} className={lang === 'hk' ? font['hk-bold'] : font['hk-medium']} >中文/港澳</li>
          <li onClick={() => { handleLanguageChange("tc") }} className={lang === 'tc' ? font['tc-bold'] : font['tc-medium']} >中文/臺灣</li>
        </ul>
      </div>
    </div>
  );
}

export default NavBar;